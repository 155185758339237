'use strict';

var util = require('./util');
var SearchButton = require('./searchbutton');

function MenuSearchUtil($searchContainer, $nav, $defaultValue, FlyoutSearch) {
    this.init = function() {
        var $searchButton = $('#search > button');

        if (!$searchButton.length) {
            return; // If there's no search button, just skip initialization entirely.
        }

        var flyout = new FlyoutSearch($searchContainer, $nav, $defaultValue).init();
        var searchButton = new SearchButton($searchButton);

        function closeSearch() {
            $searchButton.attr('aria-expanded', false);
            flyout.hide();
            searchButton.closeSearch();
        }

        function openSearch() {
            $searchButton.attr('aria-expanded', true);
            searchButton.openSearch();
            flyout.show();

            setTimeout(function() {
                document.getElementById('q-search').focus();
                if (SitePreferences.BLOOMREACH_ENABLED) {
                    $('.flyout-results-container input[name="q"]').trigger('focus');
                } else {
                    $('input[name="q"]').trigger('focus');
                }
            }, 1000); //trigger focus after animation is finished
        }

        if (!util.isMobile() && $(window).width() > 959) {
            searchButton.click(function() {
                if ($('#wrapper').hasClass('search-active')) {
                    closeSearch();
                } else {
                    openSearch();
                }
            });
        } else {
            searchButton.click(function() {
                setTimeout(function() {
                    if ($('#wrapper').hasClass('menu-active')) {
                        $('.menu-toggle').trigger('click');
                    }
                }, 500);

                if ($('#wrapper').hasClass('search-active')) {
                    closeSearch();
                } else {
                    openSearch();
                }
            });
        }

        flyout.gutterClick(closeSearch);
        flyout.onEnterEscKey(closeSearch);
    };
}

module.exports = MenuSearchUtil;
