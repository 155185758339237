'use strict';

var util = require('../util');

/**
 * @function
 * @description Adds all of the configs for the iconselectmenu
 */
function initMobileNavSettings() {
    $.widget('custom.iconselectmenu', $.ui.selectmenu, {
        _renderItem: function(ul, item) {
            var li = $('<li>');
            var wrapper = $('<div>', {text: item.label});

            if (item.disabled) {
                li.addClass('ui-state-disabled');
            }

            return li.append(wrapper).appendTo(ul);
        },
        _renderButtonItem: function(item) {
            var buttonItem = $('<span>', {
                'class': 'ui-selectmenu-text'
            });

            buttonItem.html('<span class="text-item">' + item.label + '</span>');

            if (this.element[0].length > 5) {
                buttonItem.addClass('add-padding');
            } else {
                buttonItem.removeClass('add-padding');
            }

            return buttonItem;
        }
    });
}

/**
 * @function
 * @description initializes the mobile nav selection menu
 */
function initMobileNavSelectMenu() {
    var filterClassName = 'ui-quick-filter';

    initMobileNavSettings();

    $('select.filter-type').iconselectmenu({
        appendTo: '#wrapper',
        classes: {
            'ui-selectmenu-menu': filterClassName,
            'ui-selectmenu-button': filterClassName
        },
        /*
         * Triggered when the selectmenu is created.
         */
        create: function(event, ui) {
            var pathname = document.location.pathname;
            var end = pathname.lastIndexOf("/");
            var url = pathname.slice(end);

            if (url.indexOf("orderdetails") > -1 || url.indexOf("Order-MyOrderDetails") > -1 || url.indexOf("Order-Orders") > -1) {
                url = '/orders';
            }
            var $optionSelect = $("select.filter-type option[data-url='" + url + "']");
            $('select.filter-type').val($optionSelect.val()).iconselectmenu('refresh');
        },
        /*
         * Triggered when the menu is selected.
         */
        select: function(event, ui) {
            var $optionSelect = $(this).find('option:selected');
            var url = $optionSelect.attr('data-value');

            window.location.href = url;
        },
        /*
         * Triggered when the menu is opened.
         */
        open: function(event, ui) {
            $('#quickfilterSelect-menu li.ui-menu-item').each(function(e) {
                $(this).addClass($('select.filter-type option').eq(e).attr('class')).show();
            });

            if ($(this)[0]) {
                $('#quickfilterSelect-menu li.ui-menu-item:eq(' + $(this)[0].selectedIndex + ')').hide();
            }

            var myElement = document.querySelector('#quickfilterSelect-menu .ui-menu-item:last-child');
            var bounding = myElement.getBoundingClientRect();
            var $quickFilterSelectMenu = $('#quickfilterSelect-menu');
            var extraPadding = bounding.bottom - (window.innerHeight || document.documentElement.clientHeight);

            if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
                $quickFilterSelectMenu.css('padding-bottom', '0'); // remove extra padding if the last mobile nav item is visible
            } else {
                $quickFilterSelectMenu.css('padding-bottom', extraPadding); // add extra padding if the last mobile nav item is not visible
            }
        }
    })
    .iconselectmenu('menuWidget')
    .addClass('ui-menu-icons quickfilter');
}

/**
 * @function
 * @description On scroll and resize closes the iconselectmenu
 */
function closeIconSelectMenu() {
    $(window).on('scroll', function() {
        $('select.filter-type').iconselectmenu("close");
    });

    util.smartResize(function() {
        $('select.filter-type').iconselectmenu("close");
    });
}

/**
 * @function
 * @description Updates desktop nav select menu to current page
 */
function updateDesktopNavSelectMenu() {
    var pathname = document.location.pathname;
    var end = pathname.lastIndexOf("/");
    var url = pathname.slice(end);

    $(".registered-myRL-nav-list a.selected").removeClass("selected"); //remove selected

    if (url.indexOf("orderdetails") > -1 || url.indexOf("Order-MyOrderDetails") > -1) {
        url = '/orders';
    }

    $(".registered-myRL-nav-list a[data-url='" + url + "']").addClass("selected"); //add newly selected
    if ($('.myRL-Nav-Items a.selected:visible').length > 0) {
        setTimeout(() => {
            // Check if the clicked tab is out of view
            const $tabsContainer = $('.myRL-Nav-Items a.selected:visible').parents('.accountmyRL-nav-registered');
            const tabLeft = $('.myRL-Nav-Items a.selected:visible').position().left;
            const tabWidth = $('.myRL-Nav-Items a.selected:visible').outerWidth();
            const containerScrollLeft = $tabsContainer.scrollLeft();
            const containerWidth = $tabsContainer.width();

            if (tabLeft < 0 || tabLeft + tabWidth > containerWidth) {
                // Scroll the container to bring the tab into view
                $tabsContainer.animate(
                    {
                    scrollLeft: containerScrollLeft + tabLeft - containerWidth / 2 + tabWidth / 2,
                    },
                    300 // Animation duration in milliseconds
                );
            }
        }, 50);
    }
}

var myrlQuickFilterDropDown = {
    init: function() {
        initMobileNavSelectMenu();
        closeIconSelectMenu();
        updateDesktopNavSelectMenu();
    }
};

module.exports = myrlQuickFilterDropDown;