'use strict';
const STORE = 'wishlistjson';
const ENDPOINT = Urls.wishlistJson;

function updateItem($items, removed) {
    if (removed) {
        $items.find('.remove-favorites').hide();
        $items.find('.add-to-favorites-link').show();
    } else {
        $items.find('.remove-favorites').show();
        $items.find('.add-to-favorites-link').hide();
    }
}
function updateStates (productIDs) {
    const $elements = $('.wishlist-pdp');
    productIDs.forEach((ID) => {
        const $items = $elements.filter('[data-item=\'' + ID + '\']');
        updateItem($items);
    });
    $('div.plp-favorites').addClass('enabled'); //enable clicks after favs are set
}

function initFavourites() {
    var wishlistJsonURL = ENDPOINT;
    $.getJSON(wishlistJsonURL, function(data) {
        localStorage.setItem(STORE,JSON.stringify(data));
        updateStates(data.products);
    });
}

function setFavourites() {
    var wishListData = localStorage.getItem(STORE) ? JSON.parse(localStorage.getItem(STORE)) : null;
    if (wishListData !== null && wishListData.products && wishListData.products.length > 0) {
        updateStates(wishListData.products);
    } else {
        initFavourites();
    }
}

function removeWishlistJson() {
    var wishListData = localStorage.getItem(STORE) ? JSON.parse(localStorage.getItem(STORE)) : null;
    if (wishListData !== null && wishListData.products && wishListData.products.length > 0) {
        localStorage.removeItem(STORE);
    }
}

function addPid (pid) {
    if (localStorage.getItem(STORE)) {
        var wishlistjson = JSON.parse(localStorage.getItem('wishlistjson'));
        if (wishlistjson.products.indexOf(pid) === -1) {
            wishlistjson.products.push(pid);
        }
        localStorage.setItem(STORE,JSON.stringify(wishlistjson));
    } else {
        // or initFavourites?
        localStorage.setItem(STORE,JSON.stringify({products: [pid]}));
    }
}

function removePid (pid) {
    if (localStorage.getItem(STORE)) {
        var wishlistJSON = JSON.parse(localStorage.getItem('wishlistjson'));
        wishlistJSON.products = wishlistJSON.products.filter((item) => item !== pid);
        localStorage.setItem('wishlistjson',JSON.stringify(wishlistJSON));
    } else {
        // or initFavourites?
        localStorage.setItem(STORE,JSON.stringify({products: []}));
    }
}

module.exports = {
    initFavourites: initFavourites,
    setFavourites: setFavourites,
    removeWishlistJson: removeWishlistJson,
    addPid: addPid,
    removePid: removePid,
    updateItem: updateItem
};
