'use strict';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
let stickyScrollTrigger;


function HandleAccountNavAnimation() {
    const isDisappearingNavEnabled = $('.disappearingNavEnabled').length;
    let stickyScrollContent = document.querySelector('.myRLtabs.account-SignedIn');
    if (stickyScrollContent) {
        stickyScrollContent.classList.add('account-SignedIn-Orig');
        var oldTopValue = isDisappearingNavEnabled ? -70 : 0;
        var newTopValue = isDisappearingNavEnabled ? 0 : 64;
    }

    const anim = gsap.timeline({
        duration: 0.10,
        paused: true
    });


    anim.fromTo('.account-SignedIn', {
        top: oldTopValue + 'px',
        duration: 10
    }, {
        top: newTopValue + 'px',
        duration: .5,
        ease: 'none'
    }, 0);



    stickyScrollTrigger = ScrollTrigger.create({
        trigger: stickyScrollContent,
        start: 'center top',
        endTrigger: '#footer', 
        end: 'bottom center',
        toggleClass: 'account-SignedIn-gs',
        animation: anim,
        toggleActions: 'play reverse play reverse',
        markers: false
    });
}

function accountNavAnimation() {
    if ($('.myRLtabs.account-SignedIn').length > 0){
		HandleAccountNavAnimation();
	} else {
		$('header').removeClass('disappearingNavEnabled');
        let scrollContent = document.querySelector('.slot-grid-header');
        if (scrollContent){
            scrollContent.classList.add('slot-grid-header-Orig');
        }
	}
}

function accountMenuToggle() {
	var accountMenu = $('.registered-nav-list').prop('aria-hidden', true);
	var accountBtn = $('.account-nav-registered h3').attr('role', 'button').prop('aria-expanded', false);
	accountBtn.on('click',function() {
		if($(window).width() < 960) {
			accountMenu.slideToggle();
			$('.account-nav-registered').find('h3').toggleClass('rotate');
		}
    });
	var desk = $(window).width() > 960;
	$(window).on('resize',function() {
		if($(window).width() > 960) {
			desk = true;
			$('.registered-nav-list').css('display', 'block');			
		} else {
			if(desk){				
				$('.registered-nav-list').css('display', 'none');
			    $('.account-nav-registered h3').removeClass('rotate');
			}
			desk = false;
		}
	});
}

exports.accountMenuToggle = accountMenuToggle;
exports.accountNavAnimation = accountNavAnimation;