'use strict';

// PLP Redesign 24 - JS for Product Tiles
var util = require('../../util'),
    productTileLazyLoad = require('../../components/productTile/productTileLazyLoad');

const allImageTypes = ['lifestyle','video'];
for (var i = 1; i <= 20; i++) {
    allImageTypes.push('alternate' + i);
}

/**
 * When multiple main images are defined
 * remove unnecessary, set the source
 */

function keepValidImage(container){
    container.dataset.initialized = true;
    const images = container.querySelectorAll('.js-image-cont');
    if (images.length > 1) {
        const categoryImage = Array.from(images).find((img) => !img.classList.contains('cat-default') && window.getComputedStyle(img, null).display === 'block');
        if (categoryImage) {
            if (!categoryImage.querySelector('.rlc-lazyLoad')) {
                util.setPictureSources(categoryImage.querySelector('picture'));
            }
            categoryImage.classList.add('imgLoaded');                
        }
        images.forEach((img) => {
            if(categoryImage ? img !== categoryImage : !img.classList.contains('cat-default')) {
                img.remove();
            }
        });
    }
}

function updateMainImages(tile) {    
    const mainImageCont = tile.querySelector('.js-product-tile-first-image');
    if (mainImageCont){
        keepValidImage(mainImageCont)
    } else {
        //featured tile
        const featuredImages = tile.querySelectorAll('.js-featured-image');
        featuredImages.forEach((imageCont)=>{
            keepValidImage(imageCont)
        })
    }
}

function updateCGIDs(tile, cgid, gender) {
    const addCGID = (link) => {
        link.setAttribute('href', util.appendParamsToUrl(link.getAttribute('href'), { cgid: cgid }));
    };
    tile.dataset.cgid = cgid;
    if (gender && gender !== ''){
        tile.dataset.pageGender = gender;
    }
    tile.querySelectorAll('.js-pdp-link').forEach(addCGID);
    tile.querySelectorAll('.js-qs-cta').forEach((qsCTA) => {
        addCGID(qsCTA);
        const jQueryData = $(qsCTA).data('qs');
        if (jQueryData && !jQueryData.cgid) {
            $(qsCTA).data('qs', { ...jQueryData, ...{ cgid: cgid } });
        }
    });
    const priceCGID = tile.querySelector('input.js-product-grid-catId');
    if(priceCGID){
        priceCGID.value = cgid;
    }    
}

function removeSlide(e) {
    e.stopPropagation();
    const elt = e.target;
    if (elt.src) {
        const swiper = elt.closest('.swiper');
        if (swiper && swiper.dataset.initialized === 'true') {
            const slide = elt.closest('.js-swiper-slide-container');
            if (slide && slide.dataset) {
                swiper.swiper.removeSlide(slide.dataset.swiperSlideIndex);
                if (swiper.swiper.slides.length === 3) {
                    swiper.swiper.destroy();
                    // need to clean up myself...
                    swiper.querySelectorAll('.js-product-tile-swiper-button').forEach((btn) => {
                        btn.remove();
                    });
                }
            }
        }
    }
}

function setInitialideSlide(swiper) {
    if (util.isDesktopSize() && !util.isTablet()) {
        const ignoreAlter = swiper.el.dataset.ignoreAlter === 'true';
        swiper.params.initialSlide = ignoreAlter ? 0 : 1;
    } else {
        swiper.params.initialSlide = 0;
    }
}

function reorderSlides(swiperElement) {
    const imagesCont = swiperElement.closest('.product-image');
    if (imagesCont) {
        try {
            const plpContainer = document.querySelector('.search-result-main-section');
            if (plpContainer && plpContainer.dataset.cgid) {
                const cgid = util.toHash(plpContainer.dataset.cgid);
                const hoverOverwrites = JSON.parse(imagesCont.dataset.hovers || {});
                const mainImage = imagesCont.querySelector(`.js-image-cont.cat-${cgid}`);
                let mainType;
                if (mainImage) {
                    mainType = mainImage.dataset.type;
                } else {
                    const mainDefaultImage = imagesCont.querySelector('.js-image-cont.cat-default');
                    mainType = mainDefaultImage.dataset.type;
                }

                const hoverProductType = imagesCont.dataset.hover;
                const forcedHover = imagesCont.dataset.forcedAlter === 'true';
                const hoverCategoryType = forcedHover ? false : plpContainer.dataset.catHover;                

                const slides = Array.from(swiperElement.querySelectorAll('.js-swiper-slide-container'));
                var seq = slides.reduce((acc, slide) => { acc.push(slide.dataset.type); return acc; }, []);
                let hoverType = hoverProductType || hoverCategoryType || seq[1];
                if (hoverOverwrites) {
                    // these always take precedence
                    hoverType = hoverOverwrites[Object.keys(hoverOverwrites).find((key) => key.indexOf(cgid) > -1)] || hoverType;
                }
                // get the sequence right
                const types = [...allImageTypes].filter((type) => seq.indexOf(type) > -1 && !(type === mainType || type === hoverType));
                // re-insert main and hover
                if (hoverType) {
                    types.unshift(hoverType);
                }
                if (mainType && mainType !== hoverType) {
                    types.unshift(mainType);
                }
                // sort the slide nodes
                const slidesContainer = slides[0].parentNode;
                slides.sort((a, b) => types.indexOf(a.dataset.type) - types.indexOf(b.dataset.type))
                    .forEach((node) => {
                        slidesContainer.appendChild(node);
                    });
            }
        } catch (error) {
            // no reorder!
            console.log('reorder error', error);
        }
    }
}

function initProductTileSwiper(selector, $container) {
    var el;

    if ($container) {
        var $swiperContainer = $container.find(selector);

        if (!$swiperContainer.length) {
            return;
        }

        el = $swiperContainer[0];
    }
    if (el || document.querySelector(selector)) {
        // this initialize all visible swipers
        let initialized = new Swiper(el || selector, {
            version: '6',
            direction: 'horizontal',
            loop: true,
            lazy: {
                loadOnTransitionStart: true,
                preloaderClass: 'ghosting-main'
            },
            watchOverflow: true,
            navigation: {
                nextEl: '.js-product-tile-swiper-button.swiper-button-next',
                prevEl: '.js-product-tile-swiper-button.swiper-button-prev',
            },
            on: {
                beforeInit: function (swiper) {
                    reorderSlides(swiper.el);
                    setInitialideSlide(swiper);
                },
                init: function (swiper) {
                    const isDevice = util.isMobile() || util.isTablet();
                    this.el.dataset.initialized = true;
                    swiper.el.addEventListener('slideSrcFail', removeSlide);

                    // lazy load video in the first slide on mobile
                    if (isDevice) {
                        productTileLazyLoad.lazyLoadVideos(this.slides[this.activeIndex]);

                        if (this.el.dataset.animate) {
                            swiper.lazy.loadInSlide(swiper.activeIndex + 1);
                            if (document.readyState === 'complete') {
                                this.el.classList.add('animated');
                            } else {
                                $(window).on('load', () => {
                                    this.el.classList.add('animated');
                                });
                            }
                            swiper.once('touchStart', function () {
                                this.el.classList.remove('animated');
                            });
                        }
                    } else {
                        swiper.lazy.loadInSlide(swiper.activeIndex + 1);
                    }
                    // load all the other inmages
                    // loading will trigger the removals of slides with missing images
                    swiper.once((isDevice ? 'sliderFirstMove' : 'slideChange'), function () {
                        productTileLazyLoad.lazyLoadVideos(this.el);
                        const count = this.slides.length - 2;
                        for (var i = 0; i < count; i++) {
                            this.lazy.loadInSlide(i);
                        }
                    });
                }

            }
        });
        if (!Array.isArray(initialized)) {
            initialized = [initialized];
        }
        return initialized;
    }
    return [];
}

function destroyProductTileSwiper(productTile) {
    var swiperInstance = productTile.find('.js-product-tile-swiper');

    if (swiperInstance.length && swiperInstance[0].swiper) {
        swiperInstance[0].swiper.destroy(true);
    }
}

function updateCGID(selector, container) {
    let cgid, gender;
    const mainSection = document.querySelector('.search-result-main-section');
    if (mainSection && mainSection.dataset.cgid) {
        cgid = mainSection.dataset.cgid;
        gender = mainSection.dataset.catGender
    }
    if (cgid) {
        const tilesToUpdate = (container || document).querySelectorAll(selector);
        tilesToUpdate.forEach((tile) => {
            updateMainImages(tile);
            updateCGIDs(tile, cgid, gender);
        });
    }


}

exports.updateCGID = updateCGID;
exports.initProductTileSwiper = initProductTileSwiper;
exports.destroyProductTileSwiper = destroyProductTileSwiper;
exports.init = function () {
    console.log('PLP Redesign 24 - JS for Product Tiles');
};
