/* eslint-disable no-undef */
'use strict';

var ajax = require('./ajax'),
    progress = require('./../../../app_rleu_core/cartridge/js/progress'),
    util = require('./util');


    function generateBarcode(value) {
        if ($('.pt_account').length > 0) {
            progress.show($('.pt_account'));
        } else {
            progress.show($('.eform-template'));
        }
        var _value = value;
        _value = _value.replace(/\s/g,'');
        String.prototype.splice = function(idx, rem, str) {
            return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
        };
        var barcodeValue = '';
        if (_value.length === 19 && (_value.includes("AU") || _value.includes("KR") || _value.includes("TW") || _value.includes("SG") || _value.includes("MY") || _value.includes("HK") || _value.includes("MO"))) {
            barcodeValue = _value.splice(3, 0, " ").splice(8, 0, " ").splice(13, 0, " ").splice(18, 0, " ");
          }
        var iOs = util.getDevice().ios == true;
        JsBarcode("#barcode-svg", _value, {height: "80", displayValue: false, width: ($('.eform-template').length > 0 && !iOs) ? 3 : 2});
        $('#barcode-svg').css('display', 'inline-block').css('width', ($('.eform-template').length > 0 && !iOs) ? '' : '100%').css('background-color', '#fff');
        $(".barcode-value").text(barcodeValue).css('font-size', ($('.eform-template').length > 0 && !iOs) ? '24px' : '');

        setTimeout(function() {
            if (iOs) {
                $('#barcode-rl-logo').attr('src',  $('#barcode-rl-logo').attr('src').replace('svg', 'png'));
                html2canvas(document.querySelector("#eformbarcode")).then(function (canvas) {
                    var img = canvas.toDataURL("image/jpeg");
                    $('.barcode-thumb').append($('<img />').attr("src",img).attr('id', 'barcode-img-final').attr("alt", "Barcode image").css("width", "100%"));
                    $('.barcode-thumb').show();
                    $('#eformbarcode').hide();
                });
            }
            progress.hide();
        }, 500);

    }

function showConfig1D() {
    $('.config .barcode1D').show();
    $('.config .barcode2D').hide();
}

function showConfig2D() {
    $('.config .barcode1D').hide();
    $('.config .barcode2D').show();
}

function insertAfter(referenceNode, newNode) {
    if (referenceNode.length) {
        referenceNode[0].parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
}

function createWarningLess18YearsOld() {
    var NewElement = document.createElement('span');
    NewElement.innerText = Resources.WARNING_LESS_THAN_18_YEARS_OLD;
    NewElement.id = 'warning-less-than-18';
    NewElement.className = 'error';
    return NewElement;
}

function removeWarningLess18YearsOld(el) {
    if (el.length && el.parent().children('span#warning-less-than-18')) {
        el.parent().children('span#warning-less-than-18').remove();
    }
}

function getAge(year) {
    var today = new Date();
    var age = today.getFullYear() - Number(year);
    return Number(age);
}

function initializeEvents() {
    
    $('[name$="_eform_country"]').trigger('change');
    
    var $socialMediaID = $('#SocialMediaID');
    if ($socialMediaID.val()) {
        var data = 'socialMediaID=' + $socialMediaID.val();
        progress.show($('.js-submit-termandcondition-btn').closest('form'));
        ajax.load({
            url: util.appendParamsToUrl(Urls.generateBarcode),
            type: 'POST',
            data: data,
            callback: function (data) {
                $('.js-eform-wrapper').prepend(data);
                var socialMediaID = $(data).find('#js-socialmediaID').val();
                generateBarcode(socialMediaID);
                progress.hide();
            }
        });
    }

    var countryCode = $('body').find('select[id$="_eform_countrycode"]');

    var phoneEl = $('.eform-form').find('input[id$="_eform_phoneMobile"]');
    if (phoneEl && phoneEl.length && phoneEl.hasClass("phone_au")) {
        phoneEl.removeClass("phone_au");
    }

    var countryCodeVal = countryCode.val();
    if (countryCode.length && countryCodeVal) {
        countryCode.val(countryCodeVal).trigger('change').trigger('click');
    }

    var gender = $('body').find('select[id$="_eform_gender"]');
    var genderVal = gender.val();
    if (gender.length && genderVal) {
        gender.val(genderVal).trigger('change').trigger('click');
    }

    $('.eform-form').on('focusout change', function () {
        $.each(this, function (key, value) {
            if ($(value).hasClass('valid') === true && $(value).hasClass('required') === true) {
                var fieldWrapper = $(value).parent('.field-wrapper').addClass('valid');
                if (!fieldWrapper.find('.valid-icon').length && !$(value).is('select')) {
                    $(value).parent('.field-wrapper').append('<div class="valid-icon"><span class="visually-hidden">&check;</span></div>');
                }
            } else {
                $(value).parent('.field-wrapper').find('.valid-icon').remove();
            }
        });
    });


    $(document).on('change', '#newsletter.eform-newsletter', function () {
        var $this = $(this);
        if ($this.is(":checked")) {
            $(".sub-termandcondidition").removeClass("hide");
            var inputID = $(".sub-termandcondidition").find("label.eform-label-checkbox");
            for (let item of inputID) {
                var isChecked = item.getAttribute("aria-checked");
                if (!isChecked || isChecked == "false") {
                    item.click();
                }
            }
        } else {
            $(".sub-termandcondidition").addClass("hide");
            var inputID = $(".sub-termandcondidition").find("label.eform-label-checkbox");
            for (let item of inputID) {
                var isChecked = item.getAttribute("aria-checked");
                if (isChecked && isChecked == "true") {
                    item.click();
                }
            }
        }
    });

    $(document).on('click', 'input[name=btype]', function () {
        if ($(this).attr('id') === 'datamatrix') {
            showConfig2D();
        } else {
            showConfig1D();
        }
    });

    $(document).on('click', 'input[name=renderer]', function () {
        if ($(this).attr('id') === 'canvas') {
            $('#miscCanvas').show();
        } else {
            $('#miscCanvas').hide();
        }
    });

    $(document).on('click', '.js-download-barcode', function (e) {
        e.preventDefault();
        if ($('.pt_account').length > 0) {
            progress.show($('.pt_account'));
        } else {
            progress.show($('.eform-template'));
        }

        var iOs = util.getDevice().ios == true;
        if (iOs) {
            var newData = document.getElementById('barcode-img-final').getAttribute("src").replace(/^data:image\/jpeg/, "data:application/octet-stream");
            var link = document.createElement('a');
            link.download = 'barcode.jpg';
            link.href = newData;
            link.click();
            progress.hide();
            return false;
        }

        $('#barcode-rl-logo').attr('src',  $('#barcode-rl-logo').attr('src').replace('svg', 'png'));

        setTimeout(function() {
            html2canvas(document.querySelector("#eformbarcode")).then(function (canvas) {
                var img = canvas.toDataURL("image/jpeg");
                var newData = img.replace(/^data:image\/jpeg/, "data:application/octet-stream");
                var link = document.createElement('a');
                link.download = 'barcode.jpg';
                link.href = newData;
                link.click();
                $('#barcode-rl-logo').attr('src',  $('#barcode-rl-logo').attr('src').replace('png', 'svg'));
                progress.hide();
            });
        }, 500);
    });

    $(document).on('change', 'input[name="dwfrm_eform_termandcondition"]', function () {
        var $this = $(this);

        if ($this.prop('checked')) {
            $this.removeClass("error");
            if ($(this).closest('form').find('input.error').length === 0) {
                $("#termandcondition-error").removeClass("error-msg");
            }
        } else {
            $this.addClass("error");
        }
    });

    $(document).on('change', 'input[name="dwfrm_eform_transferdata"]', function () {
        var $this = $(this);

        if ($this.prop('checked')) {
            $this.removeClass("error");
            if ($(this).closest('form').find('input.error').length === 0) {
                $("#termandcondition-error").removeClass("error-msg");
            }
        } else {
            $this.addClass("error");
        }
    });



    $(document).on('change', 'select[name$="_eform_age"]', function () {
        var $this = $(this);
        var $next = $this.next();
        if ($this.val() == 'LESS_THAN_18') {
            var NewElement = createWarningLess18YearsOld();
            insertAfter($next, NewElement);
        } else {
            removeWarningLess18YearsOld($next);
        }
    });

    $(document).on('change', 'input[name$="_eform_year"]', function () {

        var $form = $(this).closest('form');

        var eformAge = $('select[name$="_eform_age"]');
        var $next = eformAge.next();

        if (!$form.validate().element('input[name$="_eform_year"]')) {
            eformAge.attr("disabled", false);
            return false;
        }

        if ($(this).val()) {
            eformAge.attr("disabled", true);
        } else {
            eformAge.attr("disabled", false);
            return false;
        }
        var age = getAge($(this).val());
        removeWarningLess18YearsOld($next);
        switch (true) {
            case age <= 18:
                eformAge.val("LESS_THAN_18");
                var NewElement = createWarningLess18YearsOld();
                insertAfter($next, NewElement);
                break;
            case 18 < age && age < 26:
                eformAge.val("19_TO_25");
                break;
            case 25 < age && age < 36:
                eformAge.val("26_TO_35");
                break;
            case 35 < age && age < 46:
                eformAge.val("36_TO_45");
                break;
            case 45 < age && age < 56:
                eformAge.val("46_TO_55");
                break;
            case 55 < age:
                eformAge.val("56_PLUS");
                break;
            default:
                return false;
        }
        $("#hiddenEformAge").val(eformAge.val());
    });
}

exports.init = function () {
    initializeEvents();
};

exports.generateBarcode = generateBarcode;
