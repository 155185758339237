'use strict';

var fixBrokenImages = {
    init: function () {
        function fixLastImage(e) {
            const mediaCont = e.target.closest('.pdp-media-container');
            if (mediaCont) {
                const slideToRemove = e.target.closest('.swiper-slide');
                let slideList = mediaCont.querySelectorAll('.swiper-slide');
                const toRemove = [slideToRemove];
                const swiperContainer = e.target.closest('.swiper-container');
                if (swiperContainer.swiper) {

                    const picture = e.target.closest('picture')
                    const mobileUrl = JSON.parse(picture.dataset.images.replace(/'/g, '"')).mobile;
                    slideList.forEach(((slide) => {
                        if (slide !== slideToRemove && slide.classList.contains('rlc-lazyLoad')) {
                            const slideMobileUrl = JSON.parse(slide.querySelector('picture').dataset.images.replace(/'/g, '"')).mobile;
                            if (slideMobileUrl === mobileUrl) {
                                toRemove.push(slide);
                            }
                        }
                    }));
                }
                while (toRemove.length) {
                    const slide = toRemove.pop();
                    slide.remove();
                }
                slideList = mediaCont.querySelectorAll('.swiper-slide');
                if (swiperContainer.swiper) {
                    swiperContainer.swiper.updateSlides();
                    swiperContainer.swiper.update();
                    const duplicates = mediaCont.querySelectorAll('.swiper-slide-duplicate').length;
                    mediaCont.classList.toggle('full-width-last-row', (slideList.length - 4 - duplicates) % 3 === 0);
                } else {
                    // desktop
                    mediaCont.classList.toggle('full-width-last-row', (slideList.length - 4) % 3 === 0);
                }
            }
        }

        function addBrokenImagePlaceholder(e) {
            const toUpdate = e.target;
            const brokenImagePlaceholder = Urls.staticPath + 'images/broken_image_icon.svg';
            if (toUpdate.parentNode.tagName === 'PICTURE') {
                toUpdate.parentNode.querySelectorAll('source').forEach((src) => { src.srcset = brokenImagePlaceholder });
                let closest = toUpdate.closest('.rlc-block');
                if (closest) {
                    if (closest.classList.contains('rlc-lazyLoad') || closest.classList.contains('img-err-replace')) {
                        closest.classList.add('rlc-imgLoaded', 'rlc-imgError', 'use-gridimgbgColor');
                    }
                } else {
                    closest = toUpdate.closest('.img-err-replace');
                    if (closest) {
                        closest.classList.add('img-err', 'use-gridimgbgColor');
                    }
                }
            } else if (toUpdate.tagName === 'IMG') {
                toUpdate.src = brokenImagePlaceholder;
                toUpdate.classList.add('replaced-image');
                toUpdate.parentNode.classList.add('img-err', 'replaced-image-center');
            }
        }
  
        document.addEventListener('missing-pdp-media-image', fixLastImage);
        document.addEventListener('replace-missing-image', addBrokenImagePlaceholder);
    }

};

module.exports = fixBrokenImages;
