'use strict';

var ajax = require('./../../ajax'),
    minicart = require('./../../minicart'),
    aep = require('@foundation/js/aepdatacollection-util'),
    util = require('../../util'),
    tabs = require('../../../../../app_rleu_core/cartridge/js/tabs'),
    accordion = require('../../../../../app_rleu_core/cartridge/js/accordion'),
    productSchema = require('@foundation/js/product-schema');
productSchema.generateProductDetailSchemas();
var MOBILE = 'mobile',
	TABLET = 'tablet',
	DESKTOP = 'desktop',
	currentDesign;
var mobileDesignWatcher = window.matchMedia('(max-width:767px)');
var tabletDesignWatcher = window.matchMedia('(min-width:768px) and (max-width:959px)');
var desktopDesignWatcher = window.matchMedia('(min-width:960px)');

var onDesignWatcher = function(force) {
	if (mobileDesignWatcher.matches && (force || currentDesign !== MOBILE)) {
	    setUI(MOBILE);
	}
	if (tabletDesignWatcher.matches && (force || currentDesign !== TABLET)) {
	    setUI(TABLET);
	}
	if (desktopDesignWatcher.matches && (force || currentDesign !== DESKTOP)) {
	    setUI(DESKTOP);
	}
}

var initiDesignWatcher = function() {
	mobileDesignWatcher.addListener(onDesignWatcher);
	desktopDesignWatcher.addListener(onDesignWatcher);
	tabletDesignWatcher.addListener(onDesignWatcher);
	onDesignWatcher(true);
}

var setUI = function(design) {
    currentDesign = design;
    if (design === MOBILE || design === TABLET) {
    	restoreDesktopProductDetails();
        $(document).trigger('pdpscroll:disable');
        $(document).trigger('col2scroll:enable', design === TABLET);
    } else if (design === DESKTOP) {
    	addDesktopProductDetails();
        $(document).trigger('pdpscroll:enable');
        $(document).trigger('col2scroll:disable');
        $('.product-bottom-content').css('margin-top','');
    }            
}

var addDesktopProductDetails = function() {
	var $pdpMain = $('#pdpMain');
	var $wrapper = $pdpMain.find('.product-image-container');
    var $pdpDetails = $wrapper.find('.moveToLeftContent');
        
    if ($pdpDetails.length == 0 && $pdpMain.find('.product-detailed-information.pdp-details').length > 0) {
        var tab;
        // selected accordion
        var targetId;
        var $selectedTab = $pdpMain.find('.product-detailed-information.pdp-details .accordion.accordion-opened button')
        if ($selectedTab.length == 1) {
            targetId = $selectedTab.attr('aria-controls').replace('accordion-panel', 'tab');
        }

        $pdpDetails = $pdpMain.find('.moveToLeftContent').detach();

        var $headers = $pdpDetails.find('button[role="tab"]');
        $headers.each(function() {
            var $header = $(this);
            var $from = $pdpDetails.find('.product-detailed-information.pdp-details #' + $header.attr('id').replace('tab', 'accordion'));
            $header.html($from.find('button').html());
        });
        
        $pdpDetails.find('#pdp-details-accordion-panel ul').append($('<li></li>').append($pdpDetails.find('#pdp-details-accordion .style-number')));

        if($wrapper.length) {
    		$wrapper.append($pdpDetails);
        }

        if (targetId) {
            var tab = $pdpDetails.find('button#' + targetId)[0];
            tabs.focus(tab, $pdpDetails);
        }
    }
    $pdpMain.find('.not-placed').removeClass('not-placed');
}


var restoreDesktopProductDetails = function() {
	var $pdpMain = $('#pdpMain');
    var $pdpDetails = $pdpMain.find('.product-col-2.main-product-detail .moveToLeftContent');
    var tab;
    var targetId;
    // selected tab
    if ($pdpDetails.length == 0) {
        var $selectedTab = $('.pdp-details .tabs button[aria-selected="true"]');
        if ($selectedTab.length == 1) {
            targetId = $selectedTab.attr('aria-controls').replace('-panel', '');
        }
        $pdpDetails = $pdpMain.find('.moveToLeftContent').detach();
        $pdpDetails.find('#pdp-details-accordion-panel ul li:last-of-type').remove();

        $('.product-col-2.main-product-detail').find('.moveToLeftContent-cont').append($pdpDetails);
                      
        tabs.focus(null, $pdpDetails);

        if (targetId) {
            var toggle = $pdpDetails.find('#' + targetId + ' button').closest('.accordion-header');
            window.setTimeout(function() {
                toggle.trigger('click');
            }, 200);
        }
    }
    $pdpMain.find('.not-placed').removeClass('not-placed');
}


var setAddToCartHandler = function(e) {
    e.preventDefault();
    //Before adding VGC to card Must Check Max VGC amount.
    var doAddTocart = true;
    var form = $(this).closest('form');
    var $button = $(this);
    if (!form.valid()) {
    	$button.prop('disabled', true);
        return false;
    }
    var deliveryDateInput = form.find('input[name $= "purchase_deliveryDate"]');
    var deliveryDateVal = deliveryDateInput.val();
    var deliveryDateArray = deliveryDateVal.split(/\//);
    var newDeliveryDate = [deliveryDateArray[1], deliveryDateArray[0], deliveryDateArray[2]].join('/');
    deliveryDateInput.val(newDeliveryDate);
    var amountInput = form.find('input[name $= "purchase_gcAmount"]');
    var amount = amountInput.val();
    if(amount.endsWith('.00') || amount.endsWith(',00')) {
    	amount = amount.slice(0, -3);
    }
    if (Resources.CURRENCY_SYMBOL == '€') {
    	amount = amount.replace(/[€]/, '').replace(/^CHF/, '');
    	amount = amount.replace(/(\.)*/g, '').replace(/(,)*/g, '');
    } else if (Resources.CURRENCY_SYMBOL == 'A$') {
        amount = amount.replace('A$', '').replace(/(,)*/g, '');
    } else if (Resources.CURRENCY_SYMBOL == '₩') {
        amount = amount.replace('₩', '').replace(/(,)*/g, '');
    } else if (Resources.CURRENCY_SYMBOL == '¥') {
        amount = amount.replace('¥', '').replace(/(,)*/g, '');
    } else if (/^[S$]/.test(amount)) {
        amount = amount.replace(/^(S\$)/, '').replace(/(\.)*/g, '').replace(/(,)*/g, '');
    } else if (/^[RM]/.test(amount)) {
        amount = amount.replace(/^(RM)/, '').replace(/(\.)*/g, '').replace(/(,)*/g, '');
    } else {
    	amount = amount.replace(/[£]/, '').replace(/(,)*/g, '');
    }
    amountInput.val(amount);

    $.ajax({
        dataType: 'json',
        url: Urls.vgcMaxAmount,
        type: 'GET',
        async: false
    }).done(function(response) {
        var totalAmount = parseInt(amount) + response.amount;
        //Do not consider previous GC amount
        if ($('.oldGCValue').length > 0) {
        	totalAmount -= Number($('.oldGCValue').val());
        }
        var maxCartLimit = Resources.GIFT_CERT_CART_MAX_LIMIT || 2000;
        if (response != null && response != undefined && totalAmount > maxCartLimit) {
            form.find('.max-Amount-Msg').removeClass('hide');
            form.find('input[name$= "purchase_gcAmount"]').addClass('error1'); 
            $('html, body').animate({
                scrollTop: $('.product-col-2').offset().top
            }, 'slow');
            $button.prop('disabled', true);
            doAddTocart = false;
        } else {
            form.find('.max-Amount-Msg').addClass('hide');
            form.find('input[name$= "purchase_amount"]').removeClass('error1');
        }

        // Split Gift Message
        var charsLimit = $('#gift-card-message').data('character-counter');
        var charsLimitline = charsLimit / 4;
        var giftMessage = $('#gift-card-message').val();
        var giftMessageSplit = giftMessage.match(new RegExp('.{1,' + charsLimitline + '}(.\|$)', 'g'));
        var giftMessageLines = $('.gift-card-message input');
        $('.gift-card-message input').val('');
        if (giftMessage) {
            for (var i = 0; i < giftMessageSplit.length; i++) {
                $(giftMessageLines[i]).val(giftMessageSplit[i]);
            }
        }
    });
    
    if (doAddTocart) {

        var url = util.ajaxUrl(form.attr('action'))
        var options = {
            url: url,
            method: 'POST',
            cache: false,
            data: form.serialize()
        };
        $.ajax(options).done(function(response) {
            if (response.success) {
                ajax.load({
                    url: Urls.minicartGC,
                    data: {
                        lineItemId: response.result.lineItemId
                    },
                    callback: function(response) {
                    	$('#AddToBasketButton').html(Resources.Item_Add_To_Bag);
                        minicart.show(response, form, $button);
                        form.find('input,textarea').val('');
                        //Once GC is updated remove oldGCValue
                        $('.isGCUpdate').val('');
                        $('.oldGCValue').val('');
                        $('.gc-amounts a').removeClass('selected');
                        $('.js-gift-card-msg .input-checkbox').prop('checked', false );
                        $('.gift-card-message ').addClass('hide');
                        if ($('#dwfrm_giftcert_purchase_deliveryDate').length > 0){
                        	var today = new Date();
                        	today = today.getDate().toString().padStart(2, '0') + '/' + (today.getMonth() + 1).toString().padStart(2, '0') + '/' + today.getFullYear();
                        	$('#dwfrm_giftcert_purchase_deliveryDate').val(today);
                        }
                    }
                });
                form.find('span.error').remove();
                var $charcountclear = $('.gift-card-message').find('.gift-field');
                $charcountclear.find('input').val('');
                $charcountclear.find('span.char-count').find('.char-remain-count').html('0');
                aep.coreCall('addToCart', form);
            } else {
                form.find('span.error').hide();
                if (response.errors && response.errors.FormErrors) {
                    for (var id in response.errors.FormErrors) {
                        var $errorEl = $('#' + id).addClass('error').removeClass('valid').attr('aria-invalid', 'true').attr('aria-describedby', id + '-error').next('.error');
                        var $errorEltext = response.errors.FormErrors[id].replace(/\\'/g, '\'');

                        if (!$errorEl || $errorEl.length === 0) {
                            $errorEl = $('<span id="' + id + '-error" class="error" style=""></span>');
                            $('#' + id).after($errorEl);
                            //form.find('input[name $= "purchase_amount"]').focus();
                            $('html, body').animate({
                                scrollTop: $('.product-detail-section').offset().top
                            }, 'slow');
                        }
                        $errorEl.text($errorEltext).show();
                    }
                }
            }
        }).fail(function(xhr, textStatus) {
            // failed
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    }
};

var checkBalance = function (e) {
    e.preventDefault();
    var form = $(this).closest('form');
    form.validate();
    if (!form.valid()) {
        return false;
    }
    var giftCardNumber = $('input[name$="_balance_giftCertID"]').val();
    form.find('.balance-result').html('');
    var options = {
        url: util.ajaxUrl(form.attr('action')),
        method: 'POST',
        cache: false,
        data: form.serialize()
    };
    $.ajax(options).done(function (response) {
        var balance = 0;
        var status = false;
        if (response.code == 'ERROR' || response.responsecode == 10) {
            form.find('.balance-result').html(Resources.GIFT_CERT_INVALID).addClass('error');
        } else {
            form.find('.balance-result').html(Resources.GIFT_CERT_BALANCE + ' ' + response.balance);
            status = true;
            if (response.balance != undefined)
                balance = response.balance;
            if (response.code != 'OK') {
            	form.find('.balance-result').addClass('error');
            } else {
            	form.find('.balance-result').removeClass('error');
            }
        }

        aep.coreCall('giftCardApply', giftCardNumber, status, balance);
        
    });
};

var setGCamount = function (pid, e) {
	var checkPID = pid;
	var selectedValue = $(this).text().replace(/ /g, "");
	$('.gc-amounts a').removeClass('selected');
	$(this).addClass('selected');
	$('.cert-amount input').val(selectedValue);
	if($('.giftcertAmount').val() != ''){
		$('.giftcertAmount').removeClass('error');
		$('.cert-amount').find('span.error').hide();
	}
    var form = $(this).closest('form');
    form.find('.max-Amount-Msg').addClass('hide');
    form.find('input[name$= "purchase_gcAmount"]').removeClass('error1');
	btnValidate();
};

var btnValidate = function (e) {
	var form = $('#GiftCertificateForm');
	var fieldValid = false;
	
	if ($('.gc-amounts .selected').length = 0 || $('.giftcertAmount').val() != '') {
    	fieldValid = true;
	} else if ($('input[name$="_includegcmessage"]').is(':checked') || $('input[name$="_includegcmessage"]').is(':unchecked')) {
    	if(form.find('input.required.error').length || form.find('textarea.required.error').length) {
    		form.find(':input[type="submit"]').prop('disabled', true);
    	} else {
    		form.find(':input[type="submit"]').prop('disabled', false);
    	}
    } 
	
	if (fieldValid) {
	    var requiredInputs = $('.required', form).find(':input').filter(function() {
	        return this.value === '';
	    });
	    if(requiredInputs.length) {
	        fieldValid = false;
	    } else if (!$('.input.required.error').length) {
	        form.find(':input[type="submit"]').prop('disabled', false);
	        fieldValid = true;
	    }
	    if (!$('input[name$="_includegcmessage"]').is(':checked')) {
            $('#gift-card-message').val('');
        }
	}
	
	if($('input.required.error').length || $('textarea.required.error').length) {
		form.find(':input[type="submit"]').prop('disabled', true);
	} else {
		form.find(':input[type="submit"]').prop('disabled', false);
	}
}

function initializeEvents() {
    if ($('.isGiftCardProduct').val()) {
        $('.giftcertAmount').val($('input[name=selectedAmount]').val().replace(',00', ''));
    }
    var form = $('#GiftCertificateForm');
    
	$('#AddToBasketButton').off('click').on('click', setAddToCartHandler);
    $('#CheckBalanceButton').off('click').on('click', checkBalance);
    $('.gc-amounts a').off('click').on('click', setGCamount);
    form.on('keyup focus focusout', 'input', btnValidate);
    form.find('#gift-card-message').bind('keyup input propertychange', btnValidate);
    
    accordion.init(true);
    tabs.init($('.moveToLeftContent'));
    initiDesignWatcher();
    // wishlist.init();
    require.ensure([], function (require) {
        var chunk = require('../wishlist');
        if (chunk.init) {
            chunk.init();
        }
    }, function (error) {
        console.log('error!', error);
    }, 'wishlist');
    
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('vgcuuid')) {
    	var amount = $('.giftcertAmount').val().slice(0,-3).replace(/(\.)*/g, '').replace(/(,)*/g, '');
    	
    	$('.gc-amounts a').each(function() {
			var thisAmount = $(this).text().replace(/[^\d\.]/g, '').replace(/(\.)*/g, '');
			if (thisAmount == amount) {
				$(this).addClass('selected');
				$('.giftcertAmount').val($(this).text().replace(/ /g, ""));
				return false;
			}
		});

    	if ($('.gift-card-message input').val() != '') {
        	var giftMessage = '';
            $('.gift-card-message input').each(function() {
            	giftMessage += ($(this).val() + ' ');
            });
            giftMessage = giftMessage.trim();
            
            var giftMessageLength = giftMessage.length;
            $('#gift-card-message').val(giftMessage);
            $('.char-remain-count').text(giftMessageLength);
            $('.js-gift-card-msg .input-checkbox').prop('checked', true );
        	$('.gift-card-message').removeClass('hide');
        }
    }
    
    $('.giftcertAmount').on('focusout',function(e) {
    	if($('.giftcertAmount').val() === '') {
    		$('.gc-amounts a').removeClass('selected');
    	}
    });
    
    $('.check-gc-balance').on('click',function(e) {
        $('html').animate({
            scrollTop: $('.gift-card-check-balance').offset().top - $('.top-banner').height()
        }, 'slow');
    });
    
    if ($('.deliveryDate,.dwfrm_giftcert_purchase_deliveryDate').length > 0){ 
    	var deliveryDate = '';
    	if ($('.updateDeliveryDate').length > 0){
    		deliveryDate = $('.updateDeliveryDate').val();
    	}
	    $('.deliveryDate,.dwfrm_giftcert_purchase_deliveryDate').datepicker({ 
	    	dateFormat: 'dd/mm/yy',
			minDate: 0,
			showOn: 'both',
			maxDate:365,
			buttonImageOnly: true,
			buttonImage: $('.delivery-date').data('date-image'),
			beforeShow:function(textbox, instance){
				$('.divToAppendPicker').append($('#ui-datepicker-div'));
				$('.ui-datepicker').addClass('notranslate');
			}
		}).datepicker('setDate', deliveryDate ? new Date(deliveryDate) : new Date());
	    
	    $('#dwfrm_giftcert_purchase_deliveryDate').on('click change keyup mouseup blur', function(){
	    	var selectedDate = $(this).val();
        	var date = new Date(selectedDate.split('/')[1] +'/' + selectedDate.split('/')[0] + '/'+ selectedDate.split('/')[2]); 
        	var currentDate = new Date();
        	
        	if (date == 'Invalid Date' || currentDate > date) {
        		currentDate =  ('0' + currentDate.getDate()).slice(-2) + '/' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '/' + currentDate.getFullYear();
            	$('#dwfrm_giftcert_purchase_deliveryDate').val(currentDate);
        	}
        });
	    
    }

    // Gift Message
    $('#GiftCertificateForm').on('click', 'input[name$="_includegcmessage"]', function () {
        if ($(this).is(':checked')) {
            form.find('.message-line-item').removeClass('hide');
            form.find('#gift-card-message').addClass('required');
        } else {
            form.find('.message-line-item').addClass('hide');
            form.find('#gift-card-message').removeClass('required');
        }
        btnValidate();
    });
    
    $('#gift-card-message').on('keypress blur input', function(e) {
    	if (e.keyCode == 13) {
            e.preventDefault();
        }
        $(this).val($(this).val().replace(/(\r\n|\n|\r)/gm,' '));
        if($('#GiftCertificateForm').find('input.required.error').length || $('#GiftCertificateForm').find('textarea.required.error').length) {
        	$('.gc-button button').prop('disabled', true);
    	} else {
    		$('.gc-button button').prop('disabled', false);
    	}
    });

    $('#GiftCertificateForm').on('change keyup mouseup', '#gift-card-message', function () {
        var text = $(this).val();
        var charsLimit = $(this).data('character-counter');
        var charsUsed = text.length;
        var charsRemain = charsLimit - charsUsed;

        if (charsRemain <= 0 && charsUsed >= charsLimit + 1) {
            return false;
        } else {
            $(this).closest('.gift-field').find('.char-remain-count').html(charsUsed);
        }
    });

};
var giftCard = {
    init: function() {
        initializeEvents();
    }
};

module.exports = giftCard;